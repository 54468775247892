import { Vitals } from "types/entity";
import { GET_VITALS_BY_TYPE } from "./constants";
import { Action } from "types/redux";
import { ADD_VITALS, CREATE_VITALS } from "containers/Profile/services/constants";
import { VitalsPayload } from "types/payload";

const actions = {
  getVitalsByType: (payload: { relative_id: string, type: 'weight' | 'temperature' }) => ({
    type: GET_VITALS_BY_TYPE,
    payload,
  }),
  addVitals: (payload: VitalsPayload, patient_id: number): {type: string, payload: VitalsPayload, patient_id: number } => ({
    type: ADD_VITALS,
    payload,
    patient_id
  }),
  createVitals: (payload: Vitals): Action<Vitals> => ({
    type: CREATE_VITALS,
    payload,
  }),

}

export default actions;