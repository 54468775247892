export const [
  UNSET_QUESTION,
  SET_QUESTION,
  NEXT_QUESTION,
  PREVIOUS_QUESTION,
  CREATE_QUESTION,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_ERROR,
  INIT_SURVEY,
  CANCEL_SURVEY,
  SET_CUSTOMER_TYPE,
  REMOVE_QUESTION,
  RESET_SURVEY_STORE,
  GET_ALL_DOCUMENT,
  GET_ALL_DOCUMENT_REQUESTS,
  ADD_DOCUMENTS_TO_QUESTION,
  RESET_SURVEY_QUESTION_REPLIES,
  SET_VITALS_ANSWERS,
] = [
  "UNSET_QUESTION",
  "SET_QUESTION",
  "NEXT_QUESTION",
  "PREVIOUS_QUESTION",
  "CREATE_QUESTION",
  "SUBMIT_SURVEY_REQUEST",
  "SUBMIT_SURVEY_SUCCESS",
  "SUBMIT_SURVEY_ERROR",
  "INIT_SURVEY",
  "CANCEL_SURVEY",
  "SET_CUSTOMER_TYPE",
  "REMOVE_QUESTION",
  "RESET_SURVEY_STORE",
  "GET_ALL_DOCUMENT",
  "GET_ALL_DOCUMENT_REQUESTS",
  "ADD_DOCUMENTS_TO_QUESTION",
  "RESET_SURVEY_QUESTION_REPLIES",
  "SET_VITALS_ANSWERS",
]

export const [
  QUESTION_DOCTORS,
  QUESTION_PATIENT,
  QUESTION_RELATIVES,
  QUESTION_SLOTS,
] = [
  "QUESTION_DOCTORS",
  "QUESTION_PATIENT",
  "QUESTION_RELATIVES",
  "QUESTION_SLOTS",
]

export const RESET_SURVEY_REPLY = "RESET_SURVEY_REPLY"
