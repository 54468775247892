import { Carousel as AntdCarousel, Col, Row } from "antd"
import { CarouselRef } from "antd/lib/carousel"
import { first } from "lodash"
import React from "react"
import { RefObject, useEffect, useRef, useState } from "react"

import { Buttons } from "components/Card/Card"
import Spacer from "components/Spacer"
import { ITEMS_PER_CAROUSEL_PAGE } from "containers/Survey/models"
import { ACTION, Value } from "containers/Survey/types"
import {
  get_index_of_position,
  splitCarouselPages,
} from "containers/Survey/utils"
import { split as splitterArray } from "containers/Survey/utils"

import styles from "./styles.module.scss"
import { UiCarouselProps } from "./types"
import {
  UiArrow,
  UiCheckboxes,
  UiGenericSlide,
  UiInline,
  UiSelector,
  UiSlide,
} from "./Ui"

export const UiCarousel: React.FC<UiCarouselProps> = (props): JSX.Element => {
  const view = props.ui?.view || "carousel"
  const ref = useRef<CarouselRef>() as RefObject<CarouselRef>
  const [hide, setHide] = useState({ left: true, right: true })
  const pages = splitCarouselPages(props.items, ITEMS_PER_CAROUSEL_PAGE)

  // current index carousel position
  const position = props.ui?.position
    ? get_index_of_position(props.ui?.position, pages.length)
    : 0
  // previous arrow carousel
  const previous = () => ref?.current?.prev()
  // next arrow carousel
  const next = () => ref?.current?.next()
  // before carousel change
  const onBeforeChange = (from: number, to: number): void => {
    let _hide = hide
    if (to <= 0) setHide((_hide = { ..._hide, left: true }))
    else setHide((_hide = { ..._hide, left: false }))
    if (to === pages.length - 1) setHide((_hide = { ..._hide, right: true }))
    else setHide((_hide = { ..._hide, right: false }))
  }

  useEffect(() => {
    if (Number(props.items.length) <= ITEMS_PER_CAROUSEL_PAGE)
      setHide({ left: true, right: true })
    else setHide({ left: true, right: false })
  }, [props.items.length])

  let displayer = null
  switch (view) {
    case "checkbox":
      displayer = pages.map((page, key) => (
        <UiCheckboxes
          key={key}
          items={page}
          selected={props.selected}
          onSelect={props.onSelect}
          unSelect={props.unSelect}
        />
      ))
      break
    case "carousel":
      displayer = (
        <>
          <UiArrow side="left" onClick={previous} hidden={hide.left} />
          <Col span={20} style={{ textAlign: "center" }}>
            <AntdCarousel
              className={props.items.length <= 2 ? styles.ScalerCarousel : ""}
              beforeChange={onBeforeChange}
              ref={ref}
              dots={false}
              initialSlide={position}
            >
              {pages.map((page, key) => {
                return (
                  <UiSlide
                    key={key}
                    items={page}
                    selected={props.selected}
                    onSelect={props.onSelect}
                    unSelect={props.unSelect}
                  />
                )
              })}
            </AntdCarousel>
          </Col>
          <UiArrow side="right" onClick={next} hidden={hide.right} />
        </>
      )
      break
    case "inline":
      displayer = (
        <Spacer size={50}>
          {props.items.map((item, i) => {
            const selected = props.selected.includes(item.value)
            return (
              <UiInline
                key={i}
                item={item}
                imgUrl={item.imgUrl}
                disabled={item.action === ACTION.nothing}
                onClick={() => {
                  selected ? props.unSelect(item) : props.onSelect(item)
                }}
                selected={selected}
              />
            )
          })}
        </Spacer>
      )
      break
    case "selector":
      displayer = (
        <UiSelector
          items={props.items}
          onSelect={props.onSelect}
          selectedValue={first(props.selected) as Value | undefined}
        />
      )
      break
    default:
      break
  }

  return <Row className={styles.SurveyBox}>{displayer}</Row>
}

interface CarouselProps {
  items: JSX.Element[]
  itemsPerPage?: number
  position?: number
  onChange?: <T>(item: T) => void
  toSpread?: unknown[]
  infinite?: boolean
  dots?: boolean
  style?: {
    [x: string]: any
  }
}
export const Carousel: React.FC<CarouselProps> = ({
  infinite = true,
  ...props
}): JSX.Element => {
  const ref = useRef<CarouselRef>() as RefObject<CarouselRef>
  const [hide, setHide] = useState({ left: true, right: true })
  const [pages, setPages] = useState<JSX.Element[][]>([])
  const itemsPerPage = props.itemsPerPage ?? ITEMS_PER_CAROUSEL_PAGE

  // current index carousel position
  const position = props.position || 0
  // previous arrow carousel
  const previous = () => ref?.current?.prev()
  // next arrow carousel
  const next = () => ref?.current?.next()
  // before carousel change
  const onBeforeChange = (from: number, to: number): void => {
    if (props.onChange) {
      if (props.toSpread) props.onChange(props.toSpread[to])
      else props.onChange(props.items[to])
    }
    let _hide = hide
    if (to <= 0) setHide((_hide = { ..._hide, left: true }))
    else setHide((_hide = { ..._hide, left: false }))
    if (to === pages.length - 1) setHide((_hide = { ..._hide, right: true }))
    else setHide((_hide = { ..._hide, right: false }))
  }

  useEffect(() => {
    setPages(splitterArray(props.items, itemsPerPage))
  }, [props.items])

  return (
    <Row className={styles.SurveyBox}>
      <UiArrow side="left" onClick={previous} hidden={hide.left} />
      <Col
        span={20}
        style={{
          textAlign: "center",
          height: pages.length > 1 ? "550px" : "initial",
          verticalAlign: "middle",
          ...props.style,
        }}
      >
        <AntdCarousel
          infinite={infinite}
          beforeChange={onBeforeChange}
          ref={ref}
          dots={props.dots ? { className: "dots-carousel" } : false}
          initialSlide={position}
        >
          {pages.map((page, key) => {
            return <UiGenericSlide key={key} items={page} />
          })}
        </AntdCarousel>
      </Col>
      <UiArrow side="right" onClick={next} hidden={hide.right} />
    </Row>
  )
}
