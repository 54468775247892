import { createSelector } from 'reselect';
import { PATH } from "containers/Survey/models";
import { Answer } from "containers/Survey/types";
import { SurveyStore, VitalsStore } from "types/store";
import { getCurrentSelectedPatientId, getReasonAnswer } from 'containers/Survey/services/selector';
import { getVitalsNeededByReason } from '../utils';

const getSelectedReason = ({ survey }: { survey: SurveyStore }) => survey?.answers?.find((answer: Answer) => answer.questionId === PATH.vitals)?.value || ""

const getSelectedPatientVitals = createSelector(
  getCurrentSelectedPatientId,
  ({vitals}: { vitals : VitalsStore}) => vitals,
  (selectedPatientId: number, vitals: VitalsStore) => {
    return vitals?.[selectedPatientId];
  }
)

const getVitalsIdListByPatientId = createSelector(
  getCurrentSelectedPatientId,
  getReasonAnswer,
  ({vitals}: { vitals: VitalsStore}) => vitals,
  (selectedPatientId: number, currentReason, vitals: VitalsStore) => {
    const vitalsNeeded = getVitalsNeededByReason(currentReason)?.map((i) => i.label) as Array<string>;
    return vitals ? Object.keys(vitals?.[selectedPatientId
])?.filter((k) => vitalsNeeded.includes(k)).map((v) => vitals?.[selectedPatientId]?.[v]?.id && ({ value: vitals?.[selectedPatientId]?.[v]?.id })) : [];
  }
)

export { getSelectedReason, getSelectedPatientVitals, getVitalsIdListByPatientId }

