import Compliance from "lib/api-compliance"
import { Prospect, Relative } from "types/entity"
import { Call, CustomerApi, RelativeApi } from "types/payload"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import {
  HISTORY_REQUEST,
  CHANGE_PATIENT_REQUEST,
  CHANGE_PATIENT_SUCCESS,
  CHANGE_RELATIVE_REQUEST,
  HISTORY_SUCCESS,
  HISTORY_ERROR,
  CHANGE_RELATIVE_SUCCESS,
  CHANGE_RELATIVE_ERROR,
  RESET_PROFILE_STORE,
  UNSUBSCRIBE_NEWSLETTER,
} from "./constants"
const actions = {
  reset: (): ActionWithoutPayload => ({ type: RESET_PROFILE_STORE }),
  historyRequest: (): ActionWithoutPayload => ({ type: HISTORY_REQUEST }),
  historySuccess: (payload: Call[]): Action<Call[]> => ({
    type: HISTORY_SUCCESS,
    payload,
  }),
  historyError: (error: string): Action<Message> => ({
    type: HISTORY_ERROR,
    payload: { text: error, type: "error" },
  }),
  changePatientRequest: (
    p: Partial<Prospect>
  ): Action<Partial<CustomerApi>> => ({
    type: CHANGE_PATIENT_REQUEST,
    payload: new Compliance<Partial<Prospect>, Partial<CustomerApi>>(
      p
    ).please() as Partial<CustomerApi>,
  }),

  changePatientSuccess: (payload: string): Action<Message> => ({
    type: CHANGE_PATIENT_SUCCESS,
    payload: {
      type: "success",
      text: payload,
    },
  }),

  changePatientError: (payload: string): Action<Message> => ({
    type: CHANGE_RELATIVE_ERROR,
    payload: { type: "error", text: payload },
  }),

  changeRelativeRequest: (
    p: Partial<Relative>
  ): Action<Partial<RelativeApi>> => ({
    type: CHANGE_RELATIVE_REQUEST,
    payload: new Compliance<Partial<Relative>, Partial<RelativeApi>>(
      p
    ).please() as Partial<RelativeApi>,
  }),

  changeRelativeSuccess: (payload: string): Action<Message> => ({
    type: CHANGE_RELATIVE_SUCCESS,
    payload: { type: "success", text: payload },
  }),

  changeRelativeError: (payload: string): Action<Message> => ({
    type: CHANGE_RELATIVE_ERROR,
    payload: { type: "error", text: payload },
  }),

  unsubscribeNewsletter: (): ActionWithoutPayload => ({
    type: UNSUBSCRIBE_NEWSLETTER,
  }),
}
export default actions
