import { Dayjs } from "dayjs";
import { getCurrentLanguage } from "locales/languages";

export enum DoctorType {
  GENERALIST = 1,
  ORTHOPTIA = 6,
  OPHTHALMOLOGY = 7,
  LABOUR_DOCTOR = 8
}
export interface Doctor {
  id: string
  firstname: string
  lastname: string
  rpps: string
  sex: number
  category_id: DoctorType
  formation_type: string
  clinic?: {
    address: string,
  }
}
export interface AvailableDoctor extends Doctor {
  clinic_address: string,
  clinic_id: number,
  is_available: boolean,
  is_tiers_payant: boolean,
  sex: Gender
}

export interface Admin {
  email: string
  phone: string
  name: string
  created_at: Date
  updated_at: Date
  is_valid: boolean
}


export enum BirthPlace {
  FRANCE = 1,
  FOREIGN = 2,
  UNKNOWN = 3,
}

type RelativeExclude = "email" | "phone" | "is_verified" | "old-password"

export type Relative = Omit<Customer, RelativeExclude>

export type Vitals = {
  relative_id?: string,
  type: 'weight' | 'temperature',
  value?: {
    comparator: '=' | '<' | '>',
    unit: 'kg' | '°',
    raw: string | number
  },
  note?: string
};

export type IdVerificationStatus = 'verified' | 'processing' | 'canceled' | 'requires_input' | 'redacted' | 'failed' | 'legacy' | 'mismatched' | 'retry' | 'refused' | null

export type VerifiedFields = {
    first_birth_firstname?: boolean
    birth_lastname?: boolean
    sex?: boolean
    birth_location?: boolean
    birthdate: boolean
}

export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export interface Customer {
  id: number
  firstname: string
  first_birth_firstname?: string
  lastname: string
  birth_lastname?: string
  gender: Gender
  birthdate: Dayjs
  birth_location: string
  email: string
  phone: string
  nir: boolean | string
  is_verified: boolean
  created_at: Dayjs
  updated_at: Dayjs
  "old-password"?: string
  enabled2fa?: boolean
  insee_code?: number | string
  birth_country?: string
  id_verification_status?: IdVerificationStatus
  id_verification_fields?: VerifiedFields
  password_expiration_date: Dayjs // "YYYY-MM-DD"
  password_reset_at: Dayjs // ISO date
  password?: string
}

export interface Prospect extends Customer {
  password?: string;
  signup_by_carte_vitale?: boolean
}

export interface Relationship {
  carer: Carer
  code: RELATIONSHIP
  label: string
}
export interface Carer {
  id?: string
  guid: string
  carer_type: CARER
  rpps?: string
  firstname: string
  lastname: string
  birth_lastname?: string
  address: string
  email: string
  phone: string
  birthdate: any
  relationship?: RELATIONSHIP // if a current_patient is defined
  profession?: string
  is_carer?: boolean
}

export enum CARER {
  RELATIVE = 1,
  PROFESSIONAL = 2,
}

export enum RELATIONSHIP {
  FTH = "FTH", //  Père
  MTH = "MTH", //  Mère
  SONC = "SONC", //  Fils
  DAUC = "DAUC", //  Fille
  HUSB = "HUSB", //  Epoux
  WIFE = "WIFE", //  Epouse
  GRFTH = "GRFTH", //  Grand-père
  GRMTH = "GRMTH", //  Grand-mère

  GRNDSO = "GRNDSO", //  Petit-fils
  GRNDDAU = "GRNDDAU", //  Petite-fille
  BRO = "BRO", //  Frère
  SIS = "SIS", //  Soeur
  UNCLE = "UNCLE", //  Oncle
  AUNT = "AUNT", //  Tante
  COUSN = "COUSN", //  Cousin(e)
  NEPHEW = "NEPHEW", //  Neveu

  NIECE = "NIECE", //  Nièce
  STPFTH = "STPFTH", //  Beau-père - époux du père ou de la mère
  STPMTH = "STPMTH", //  Belle-mère - épouse du père ou de la mère
  HBRO = "HBRO", //  Demi-frère
  HSIS = "HSIS", //  Demi-soeur
  DOMPART = "DOMPART", //  Concubin(e) ou partenaire PACS
  SIGOTHR = "SIGOTHR", //  Conjoint
  TUTEUR = "TUTEUR", //  Tuteur

  ROOM = "ROOM", //  Personne vivant sous le même toit
  NBOR = "NBOR", //  Voisin(e)
  GGRFTH = "GGRFTH", //  Arrière-grand-père
  GGRMTH = "GGRMTH", //  Arrière-grand-mère
  FRND = "FRND", //  Autre proche
  FAMMEMB = "FAMMEMB", //  Autre membre de la famille
  CURATEUR = "CURATEUR", //  Curateur
  MSVG = "MSVG", //  Mandataire de sauvegarde

  NMTH = "NMTH", //  Mère biologique
  NFTH = "NFTH", //  Père biologique
  DAU = "DAU", //  Fille biologique
  SON = "SON", //  Fils biologique
  CHILD = "CHILD", //  Enfant
}


export const RELATIONSHIP_LABEL = (function() {
  const labels = {
    fr: {
      MTH: "Mère",
      FTH: "Père",
      NMTH: "Mère biologique",
      NFTH: "Père biologique",
      STPMTH: "Belle-mère - épouse du père ou de la mère",
      STPFTH: "Beau-père - époux du père ou de la mère",
      GRMTH: "Grand-mère",
      GRFTH: "Grand-père",
      GGRMTH: "Arrière-grand-mère",
      GGRFTH: "Arrière-grand-père",
      DAU: "Fille biologique",
      SON: "Fils biologique",
      BRO: "Frère",
      SIS: "Soeur",
      HBRO: "Demi-frère",
      HSIS: "Demi-soeur",
      GRNDDAU: "Petite-fille",
      GRNDSO: "Petit-fils",
      UNCLE: "Oncle",
      AUNT: "Tante",
      NEPHEW: "Neveu",
      NIECE: "Nièce",
      HUSB: "Epoux",
      WIFE: "Epouse",
      DOMPART: "Concubin(e) ou partenaire PACS",
      ROOM: "Personne vivant sous le même toit",
      FRND: "Autre proche",
      NBOR: "Voisin(e)",
      FAMMEMB: "Autre membre de la famille",
      CHILD: "Enfant",
      COUSN: "Cousin(e)",
      SIGOTHR: "Conjoint",
      DAUC: "Fille",
      SONC: "Fils",
      TUTEUR: "Tuteur",
      CURATEUR: "Curateur",
      MSVG: "Mandataire de sauvegarde",
    },
    en: {
      MTH: "Mother",
      FTH: "Father",
      NMTH: "Biological Mother",
      NFTH: "Biological Father",
      STPMTH: "Stepmother - wife of father or mother",
      STPFTH: "Stepfather - husband of father or mother",
      GRMTH: "Grandmother",
      GRFTH: "Grandfather",
      GGRMTH: "Great-grandmother",
      GGRFTH: "Great-grandfather",
      DAU: "Biological Daughter",
      SON: "Biological Son",
      BRO: "Brother",
      SIS: "Sister",
      HBRO: "Half-brother",
      HSIS: "Half-sister",
      GRNDDAU: "Granddaughter",
      GRNDSO: "Grandson",
      UNCLE: "Uncle",
      AUNT: "Aunt",
      NEPHEW: "Nephew",
      NIECE: "Niece",
      HUSB: "Husband",
      WIFE: "Wife",
      DOMPART: "Partner or Civil Partner",
      ROOM: "Housemate",
      FRND: "Other Close Friend",
      NBOR: "Neighbor",
      FAMMEMB: "Other Family Member",
      CHILD: "Child",
      COUSN: "Cousin",
      SIGOTHR: "Significant Other",
      DAUC: "Daughter",
      SONC: "Son",
      TUTEUR: "Tutor",
      CURATEUR: "Curator",
      MSVG: "Safeguard Agent"
    }
  }
  const lang = getCurrentLanguage()
  return labels[lang]
})();