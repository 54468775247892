import { Button as AntdButton, ButtonProps } from "antd"
import { omit } from "lodash"
import React, { forwardRef, Ref } from "react"

import styles from "./button.module.scss"

const Button: React.FC<
  Omit<ButtonProps, "size"> & { ref?: Ref<HTMLElement> } & {
    wide?: "long" | "default" | "short"
    size?: "small" | "xsmall" | "large" | "middle"
    htmlType?: string
  }
> = forwardRef(({ children, ...rest }, ref) => {
  const style = {
    ...rest.style,
    paddingLeft: rest.wide === "long" ? "100px" : "30px",
    paddingRight: rest.wide === "long" ? "100px" : "30px",
  }
  return (
    <AntdButton
      className={`${styles.Button} ${rest.size === "xsmall" && styles.Xsmall}`}
      size={rest.size && rest.size !== "xsmall" ? rest.size : "large"}
      htmlType={rest?.htmlType || 'submit'}
      {...omit(rest, "style", "size")}
      style={style}
      ref={ref}
    >
      {children || null}
    </AntdButton>
  )
})

export default Button
