import React from 'react';
import { Col, Row } from 'antd';
import styles from './Temperature.module.scss';


export default function Temperature({ value, name }) {
  return (
    <div style={{minWidth: '600px', marginLeft: '120px'}}>
      <Row gutter={[20, 0]}>
        <Col span={4}>
        <div className={styles.TemperatureInput}>
          {value?.[0]}
        </div>
        </Col>
        <Col span={4}>
        <div className={styles.TemperatureInput}>
        {value?.[1]}
        </div>
        </Col>
        <div className={styles.Comma} style={{position: 'relative', top: '25px'}}>
        ,
        </div>
        <Col span={4}>
        <div className={styles.TemperatureInput}>
        {value?.[2]}
        </div>
        </Col>
        <Col span={2}>
          <div className={styles.Comma} style={{marginLeft: '90px'}}>°C</div>
        </Col>
        <input type="number" name={name} hidden maxLength={3} value={value} />
      </Row>
    </div>
  )

}