
import { reasonValues } from "containers/Survey/models"

export const vitals = {
  WEIGHT: 'weight',
  TEMPERATURE: 'temperature',
};

export const vitalsNeededByReason: any = {
  [reasonValues.COVID]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.ALLERGIES]: [
    {label: vitals.WEIGHT}
  ],
  [reasonValues.BURN]: [
    {label: vitals.WEIGHT}
  ],
  [reasonValues.CONTRACEPTION]: [
    {label: vitals.WEIGHT}
  ],
  [reasonValues.CYSTITIS]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.DIARRHEA_OR_VOMITING]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.JOINT_PAIN]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.TOOTH_PAIN]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.BACK_PAIN]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.RASH]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.TIRED]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.FEVER]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.SEXUAL_INFECTION]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.SORE_THROAT]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.HEADACHE]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.STOMACH_ACHE]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.EYE_PAIN]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.OTITIS]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.STING]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.PRESCRIPTION_REFILL]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.PERIOD_PAIN]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.LAB_RESULTS]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.COLD_AND_FLU]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.SINUS]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.COUGH]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ],
  [reasonValues.OTHER_REASON]: [
    {label: vitals.WEIGHT}, {label: vitals.TEMPERATURE, alwaysNeeded: true}
  ]
};

export const GET_VITALS_BY_TYPE = 'GET_VITALS_BY_TYPE';