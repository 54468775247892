import { createSelector } from "reselect"

import {
  getCustomer,
  getNirReaderResponse,
  getRelatives,
} from "client/services/selector"
import { isPatientExempted } from "lib/sesam"
import { Customer, Relative } from "types/entity"
import { Gender } from "types/entity"
import { NirReaderResponse, SurveyStore } from "types/store"

import { PATH } from "../models"
import { Answer, Question, Value } from "../types"

const getSurveyId = ({ survey }: { survey: SurveyStore }): string | undefined =>
  survey?.current?.id

const getCurrentSelectedPatientId = ({
  survey,
}: {
  survey: SurveyStore
}): Value | undefined => {
  let selectedPatientId: Answer | undefined
  selectedPatientId = survey?.answers?.find(
    (answer: Answer) => answer.questionId === PATH.patient
  )
  if (selectedPatientId?.value[0] === -1) {
    selectedPatientId = survey?.answers?.find(
      (answer: Answer) => answer.questionId === PATH.relative
    )
  }
  return selectedPatientId?.value[0] as number
}

const getCurrentPatient = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): Customer | Relative => {
    if (selectedPatientId === 0) {
      return patient
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative
  }
)

const getReasonAnswer: any = ({ survey }: { survey: SurveyStore }) => survey?.answers?.find((answer: Answer) => answer.questionId === PATH.reason)?.value?.[0];

const isPregnantQuestionSet = ({
  survey,
}: {
  survey: SurveyStore
}): boolean => {
  return survey?.questions?.some(
    (question: Question) => question.id === PATH.pregnant
  )
}

const getCurrentPatientFirstName = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): string | undefined => {
    if (selectedPatientId === 0) {
      return patient?.firstname
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.firstname
  }
)

const getCurrentPatientGender = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): string | number | undefined => {
    if (selectedPatientId === 0) {
      return patient?.gender || Gender.MALE
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.gender
  }
)

const getCurrentPatientBirthLocation = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): string | number | undefined => {
    if (selectedPatientId === 0) {
      return patient?.birth_location
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.birth_location
  }
)

const isCurrentPatientExempted = createSelector(
  getCurrentSelectedPatientId,
  getNirReaderResponse,
  (selectedPatientId, nirReaderResponse?: NirReaderResponse) => {
    // si !nirReaderResponse, nous ne pouvons pas savoir s'il est exempté
    if (!nirReaderResponse) return false
    else {
      if (selectedPatientId === 0) {
        // Le patient souhaitant faire une TLC est le patient principal
        return nirReaderResponse.patient
          ? isPatientExempted(nirReaderResponse.patient)
          : false
      } else {
        // le patient souhaite faire une TLC pour un proche
        // relatives = API relatives
        // nirReaderResponse.relative !=  API.relatives
        // Nous ne sommes pas en mesure de relier le proche en DB et le proche data Nir
        // car les noms ont pu être changé et l'id n'existe pas en Nir
        // nous refusons donc l'exemption par défaut
        return false
      }
    }
  }
)

const getCurrentPatientNir = createSelector(
  getCurrentSelectedPatientId,
  getCustomer,
  getRelatives,
  (
    selectedPatientId,
    patient: Customer | undefined,
    relatives: Relative[]
  ): boolean => {
    if (selectedPatientId === 0) {
      return !!patient?.nir
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return !!selectedRelative?.nir
  }
)

export {
  getSurveyId,
  getReasonAnswer,
  isCurrentPatientExempted,
  getCurrentSelectedPatientId,
  getCurrentPatientGender,
  getCurrentPatientBirthLocation,
  getCurrentPatientNir,
  getCurrentPatientFirstName,
  isPregnantQuestionSet,
  getCurrentPatient,
}
