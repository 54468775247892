import { all, put, select, takeLatest } from "redux-saga/effects"
import { push } from "redux-first-history";

import { QUESTION_PATIENT, QUESTION_RELATIVES, SET_QUESTION, GET_ALL_DOCUMENT_REQUESTS, ADD_DOCUMENTS_TO_QUESTION, SET_VITALS_ANSWERS } from "./constants"
import { SET_CUSTOMER, SET_RELATIVES } from "client/services/constants"
import { Action, GFlow, GWatcher } from "types/redux"
import { Customer, Gender, Relative } from "types/entity"
import {
  getCurrentPatientGender,
  getCurrentPatientBirthLocation,
  getCurrentSelectedPatientId,
  getSurveyId,
  isPregnantQuestionSet,
  getReasonAnswer,
} from "./selector"
import actions from "./actions"
import vitalsAction from 'containers/Vitals/services/action';
import { PATH, pregnant } from "../models"
import { Question } from "../types"
import { CustomerType } from "types/props"
import { request } from "lib/request"
import { ADD_DOCUMENTS_TO_STORE } from "containers/Upload/services/constants"
import { SET_RELATIVE_PAGE } from "core/constants";
import { getSelectedPatientVitals, getVitalsIdListByPatientId } from "containers/Vitals/services/selector";
import { getVitalsNeededByReason } from "containers/Vitals/utils";

function* createRelativesQuestion({
  payload,
}: Action<Relative[]>): GFlow<Action<Relative[]>> {
  if (payload) {
    yield put({
      type: QUESTION_RELATIVES,
      payload,
    })
  } else yield put({ type: QUESTION_RELATIVES, payload: [] })
}

function* createPatientQuestion(
  action: Action<Customer>
): GFlow<Action<Customer>> {
  if (action.payload)
    yield all([put({ type: QUESTION_PATIENT, payload: action.payload })])
}

function* manageSurveyQuestions(): GFlow<
  Action<Question | "patient" | "relative">
> {
  const currentQuestionPath = yield select(getSurveyId)
  if(currentQuestionPath === PATH.reason) {
    const vitals = yield select(getSelectedPatientVitals);
    const currentReason = yield select(getReasonAnswer);
    const vitalsNeeded = getVitalsNeededByReason(currentReason);
    // if no vitals need to be always ask
    // and at least one vitals is not set
    if(!vitalsNeeded.find((v) => v.alwaysNeeded) && !vitalsNeeded.find((v) => !vitals?.[v.label]?.value.raw)) {
      yield put(actions.remove(PATH.vitals));
      // inject all persistent value as answer
    }
  }

  // si weight est l'unique question et qu'elle est rempli => la supprimé !
  if(currentQuestionPath === PATH.illness) {
    const currentReason = yield select(getReasonAnswer);
    const vitalsNeeded = getVitalsNeededByReason(currentReason);
    const vitals = yield select(getSelectedPatientVitals);
    if(!vitalsNeeded.find((v) => v.alwaysNeeded) && !vitalsNeeded.find((v) => !vitals?.[v.label]?.value.raw)) {
      yield put(actions.remove(PATH.vitals));
      // inject all persistent value as answer
    }
  }

  if(currentQuestionPath === PATH.patient || currentQuestionPath === PATH.relative) {
    const currentPatientId = yield select(getCurrentSelectedPatientId);
    yield put(vitalsAction.getVitalsByType({ relative_id: currentPatientId !== 0 ? currentPatientId : undefined, type: 'weight' }))
  }
  if ([PATH.patient, PATH.relative, PATH.inform].includes(currentQuestionPath)) {
    const currentPatientGender = yield select(getCurrentPatientGender)
    const currentPatientBirthLocation = yield select(getCurrentPatientBirthLocation)
    if(currentQuestionPath === PATH.relative){
      if(![Gender.MALE, Gender.FEMALE].includes(currentPatientGender) || !currentPatientBirthLocation) {
        yield put(push(SET_RELATIVE_PAGE))
      }
    }
    if ([Gender.MALE, Gender.FEMALE].includes(currentPatientGender))
      yield displayPregnantQuestion(currentPatientGender)

    const currentCustomerId = yield select(getCurrentSelectedPatientId)
    if (currentCustomerId !== undefined)
      yield modifyTitleQuestion(currentCustomerId, currentPatientGender)
  }
}

function* modifyTitleQuestion(
  customerId: number,
  gender?: Gender
): GFlow<Action<CustomerType>> {
  if (customerId === 0) {
    yield put(actions.for("patient"))
    // customer is the main patient
  } else {
    // customer is a relative
    if (gender || gender === Gender.MALE) yield put(actions.for("relative_male"))
    if (gender === Gender.FEMALE) yield put(actions.for("relative_female"))
  }
}

function* displayPregnantQuestion(gender: Gender): GFlow<Action<Question>> {
  const isQuestionPregnantSet = yield select(isPregnantQuestionSet)

  if (gender === Gender.FEMALE) {
    if (!isQuestionPregnantSet) {
      yield put(
        actions.create({ question: pregnant, options: { after: PATH.drug } })
      )
    }
  } else {
    if (isQuestionPregnantSet) {
      yield put(actions.remove(PATH.pregnant))
    }
  }
}

function* getAllDocuments() {
  const currentCustomerId = yield select(getCurrentSelectedPatientId)
  try {
    const response = yield request(`/documents/customer/all`, {
      method: "GET",
    })
    if (currentCustomerId === 0) //customer is the main patient
    {
      const newResponse = response.filter( res => res.relative_id === null)
      yield put({ type: ADD_DOCUMENTS_TO_STORE, payload: newResponse })
      yield put({ type: ADD_DOCUMENTS_TO_QUESTION, payload: newResponse })
    }
    else {
      const newResponse = response.filter( res => res.relative_id === currentCustomerId)
      yield put({ type: ADD_DOCUMENTS_TO_STORE, payload: newResponse })
      yield put({ type: ADD_DOCUMENTS_TO_QUESTION, payload: newResponse })
    }

  }catch(err) {
    console.error(err, {
      route: "/documents/customer/all"
    })
  }
}

function* setVitalsAnswers() {
  const selectedVitalsId = yield select(getVitalsIdListByPatientId);
  yield put(actions.setStepReplies({replies: selectedVitalsId, id: PATH.vitals}));
}


function* surveyWatcher(): GWatcher {
  yield takeLatest(SET_RELATIVES, createRelativesQuestion)
  yield takeLatest(SET_CUSTOMER, createPatientQuestion)
  yield takeLatest(SET_QUESTION, manageSurveyQuestions)
  yield takeLatest(GET_ALL_DOCUMENT_REQUESTS, getAllDocuments)
  yield takeLatest(SET_VITALS_ANSWERS, setVitalsAnswers);
}

export default surveyWatcher
