import React, { useState } from "react"
import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { RESET_STORE } from "client/services/constants"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import { LOGIN_PAGE } from "core/constants"
import { languages } from "locales/languages"
import { Action, ActionWithoutPayload, Message } from "types/redux"
import { GlobalStore } from "types/store"
import styles from './ResetPassword.module.scss';
import { cdn } from "core/cdn"

import actions from "./services/actions"
import { Modal } from "antd"
import { GenericModal } from "components/Modal/Modal"
import dayjs from "dayjs"
import { API_DATE_FORMAT } from "lib/utils"

interface ResetPasswordProps {
  message?: Message
  loading?: boolean
  requestIsMade?: boolean
  history: { push: (s: string) => void }
  resetStore: () => void
}

const ResendBtn = ({
  text,
  Img,
  onClick,
}: {
  text: string
  Img: string
  onClick: () => void
}): JSX.Element => {
  return (
    <div className={styles.twoFaModalBtn} onClick={onClick}>
      <img className={styles.resendBtnImage} src={Img} />
      <span>{text}</span>
    </div>
  )
}
const ResetPassword: React.FC<ResetPasswordProps> = (
  props: ResetPasswordProps
) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [renewPasswordModal, setRenewPasswordModal] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [currentTransport, setCurrentTransport] = useState<string>('email');
  const [askBirthdateModal, setAskBirthdateModal] = useState<boolean>(false);

  if (props.requestIsMade) {
    return (
      <Card title={languages.resetPasswordRequestIsMadeTitle(currentTransport)} subtitle={currentTransport === 'email' && languages.ifMailIsMissing} message={props.message}>
        <Buttons>
          <Button
            onClick={() => {
              props.resetStore()
              navigate(LOGIN_PAGE)
            }}
          >
            {languages.comeBackToLogin}
          </Button>
        </Buttons>
      </Card>
    )
  }

  const inputsObj = [
    {
      name: "phone",
      required: true,
      label: languages.resetPasswordTitle,
      extra: languages.tooltipResetPassword,
    },
  ]
  return (
    <>
      <Card message={props.message}>
        <FormPage
          loading={props.loading}
          inputs={inputsObj}
          onFinish={({ phone }: { phone: string }) => { setPhone(phone); setRenewPasswordModal(true) }}
          acceptText={languages.next}
          onCancel={() => {
            navigate(LOGIN_PAGE)
          }}
        />
      </Card>
      <Modal width="100%"
        centered
        title={<p className={styles.modalTitle}>
          {languages.choosePasswordRenewMethod}
        </p>}
        closable={false}
        open={renewPasswordModal}
        onCancel={() => setRenewPasswordModal(false)}
        footer={[
          <Buttons key="resend" style={{ paddingTop: 0, marginTop: 0, marginBottom: '60px' }}>
            <ResendBtn text={languages.sms} Img={cdn("/images/sms_picto.svg")} onClick={() => { setCurrentTransport('sms'); setAskBirthdateModal(true); setRenewPasswordModal(false) }} />
            <ResendBtn text={languages.Email} Img={cdn("/images/mail_picto.svg")} onClick={() => { setCurrentTransport('email'); dispatch(actions.resetPasswordRequest({ phone, transport: 'email' })) }} />
          </Buttons>
        ]}>
      </Modal>
      <GenericModal visible={askBirthdateModal} >
        <FormPage acceptText="suivant"
          onCancel={() => { setAskBirthdateModal(false) }}
          inputs={[{ name: 'birthdate', required: true }]}
          onFinish={(value) => { dispatch(actions.resetPasswordRequest({ phone, transport: 'sms', birthdate: dayjs(value?.birthdate).format(API_DATE_FORMAT) })) }}
        ></FormPage>

      </GenericModal>
    </>
  )
}

const mapStateToProps = (
  state: GlobalStore
): Omit<ResetPasswordProps, "resetPassword" | "resetStore" | "history"> => {
  return state.resetPassword
}

const mapDispatchToProps = (
  dispatch: (a: Action<{ phone: string }> | ActionWithoutPayload) => void
): Omit<ResetPasswordProps, "loading" | "success" | "history"> => ({
  resetStore: () => dispatch({ type: RESET_STORE })
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
