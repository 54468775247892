import config from "react-global-configuration"
import { put, takeLatest } from "redux-saga/effects"

import { request } from "lib/request"
import {
  GWatcher,
} from "types/redux"

import actions from "./actions"
import { RESET_PASSWORD_REQUEST } from "./constants"
import { languages } from "locales/languages"

function* resetPasswordWithTransport({ payload }: {type: string, payload: { phone: string, transport: 'sms' | 'email', birthdate?: string }}) {
  try {
    const res = yield request(`${config.get('patient.sendLinkResetPassword')}/${payload.transport}`, { method: 'POST', payload: { phone: payload.phone, birthdate: payload?.birthdate}})
    if(res.status === "ok") {
      yield put(actions.resetPasswordRequestIsMade(languages.resetPasswordRequestIsMadeTitle))
    }
  } catch(error) {
    console.error('/customers/recover_password/<transport>');
    yield put(actions.resetPasswordRequestIsMade(languages.resetPasswordRequestIsMadeTitle))
  }
}

function* patientWatcher(): GWatcher {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordWithTransport)
}

export default patientWatcher
