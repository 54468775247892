import { cdn } from "core/cdn"

const en = {
    understood: "Understood",
    birthLocation_with_insee_code: "Place / INSEE birth code ",
    birth_lastname: "Last name",
    birth_location: "Birthplace",
    birth_firstname: "First name",
    pseudo_firstname: "Preferred first name",
    pseudo_lastname: "Preferred last name",
    verified_identity: "Identity verified",
    add_pseudo_firstname: "ADD A FIRST NAME",
    add_pseudo_lastname: "ADD A LAST NAME",
    identity_data: "Identity data",
    birth_names: "Full birth name",
    pseudo_names: "Preferred First name/Last name",
    some_information_comes_from: "Some information on your profile has been retrieved via your",
    carte_vitale: "carte vitale",
    and_are_not: "and are not",
    modifiable: "modifiable.",
    sex: "Sex:",
    you_can_add: "You can add ",
    a_pseudo_last_name_different: "a different preferred last name",
    a_pseudo_first_name_different: "a different preferred first name",
    of_your_birth_last_name: "of your last name.",
    of_your_birth_first_name: "of your first name.",
    not_informed: "Not filled in",
    not_informed_singular: "Not filled in",
    date_of_birth: "Date of birth",
    code_insee: "INSEE birth code",
    information_from_carte_vitale: "Information retrieved from your Carte Vitale",
    look_and_verify_relatives_info: "Verify and complete the identity of your relative.",
    ICanWait: "No, I can wait",
    IWantToLeave: "Yes",
    doctorWillBeHereSoon: "The doctor will be here soon...",
    woudlYouRatherCancel: "Are you sure you want to cancel the teleconsultation request and leave the queue ?",
    doctorIsComingInLessThanFiveMinutes: "The doctor will arrive in less than five minutes.",
    lessThanFiveMinutes: "less than 5min.",
    youCanJoinCallsRomm: "You can now join the calls room and wait for the doctor for a few minutes.",
    joinVirtualRoom: "Join virtual room",
    accessibility_declaration: "Accessibility declaration",
    twoFa_choseMethodForResend: "Choose a method to receive the verification code again.",
    twoFa_enterRecievedCode: "Enter the verification code received",
    bySMSAt: "by SMS at",
    byPhoneAt: "by phone at",
    byEmail: "by Email",
    RelativeAddedSuccessfully: "Congratulations, you've added a relative",
    twoFa_enterValideCode: "Enter valide code.",
    twoFa_resendCode: "RESEND CODE",
    twoFa_codeHasBeenResentBy: (transport) => `The code has been sent by ${transport}`,
    sms: "SMS",
    voice: "voice call",
    twoFa_Titre: "Verification code",
    twoFa_invalidCode: "The verification code is incorrect",
    twoFa_activationError: "An error occurred while activating 2FA on your account.",
    twoFa_ResendError: "An error occurred while resending the verification code.",
    twoFa_activateTitle: "Secure your account by enabling two-factor authentication(2FA)",
    twoFa_activateSubtitle: "To log in to your account, in addition to your password, you will receive a 6-digit code.",
    diagnosis_authorization_on_going: "Authorization in progress",
    retry: "Retry",
    relaunchTest: `RELAUNCH THE TEST`,
    iTurnedDevicesOff: "I turned off the tools",
    pharmacistsPlease: "Pharmacists, please:",
    authorizeAccess: `Authorize access to microphone and/or camera then relaunch the test.`,
    disconnectAndReconnect: `Disconnect and reconnect the camera cable then relaunch the test.`,
    ifYouNeedHelp: `If you need help, you can contact support at`,
    diagnosis_information_unknown_error: "The microphone and/or camera do not seem to be working. Please approach the kiosk attendant.",
    diagnosis_title_unknown_error: "Technical errors identified",
    diagnosis_contact_support: "Pharmacists, contact support if needed :",
    diagnosis_information_permission_denied: "The microphone and/or camera do not seem to be allowed by the browser. Please approach the kiosk attendante.",
    diagnosis_title_permission_denied: "The caméra and/or microphone are unauthorized",
    diagnosis_information_waiting_device: "You need to authorize the microphone and camera.",
    diagnosis_title_waiting_device: "Authorization request in progress...",
    diagnosis_title_success: "Retrieving video and audio streams",
    majorityRequired: "Legal majority required",
    majorityRequiredInfo: "An account Owner must be at least 16 years old at the time of registration.",
    majorityRequiredInfoFirstChunk: "An account Owner must be ",
    majorityRequiredInfoSecondChunk: "at least 16 years old ",
    majorityRequiredInfoLastChunk: "at the time of registration.",
    minAgeMessage: (minAge:number)=> `Age must be at least ${minAge} years.`,
    majorityRequiredAddRelative: "You can add a minor relative at the time of the teleconsultation.",
    createYourAccountBeforeAdddingChildRelative: "If you are consulting for your child, please create your own account first.",
    createChildRelativeAfter: "Once your account is created, you can add your child as a relative.",
    addChildRelativeWithYourOwnVitalCard: "For a child, The social security number to be provided should be that of the parent to whom the child is linked.",
    fix: "Fix",
    keepThisNumber: "keep This Number",
    generalist: "Generalist Practitioner",
    modifyInformations: "Modify Information",
    uploadDocumentQuestion: "Would you like to send medical documents to the doctor? ",
    uploadDocumentTypesHints: "(medical tests, prescription, wound, ...)",
    uploadDocumentAdd: "Import then select documents",
    uploadDocumentSelectOrAdd:"Select documents to send or add a new document",
    uploadDocumentNoDocumentAvailable: "No document available",
    uploadDocumentAddButton: "New document",
    uploadDocumentGetReady: "Get ready to position your document inside the green area facing the camera",
    uDocScanQrCode: "Scan the QR Code using ",
    yourPhonesCamera: "your phone",
    thenFollowTheSteop: " and follow the provided steps",
    uDImportFRom: "Share documents/photos",
    uDImportFromKiosk: "Or using the kiosk camera",
    uDImportFromMobile: "With my phone",
    UDSendMeSMS: "Or receive the link by SMS",
    UDAskSms: "Request a link by SMS, then follow the steps indicated",
    UDGetLinkBySms: "Receive the link by SMS",
    UDUseBorn: "Use the terminal camera instead",
    UDCannotScan: "I can't scan the QR code",
    UDModalText: "The quality of the photo will be much better with your phone, what do you want to do ?",
    UDModalAcceptQRcode: "Use my phone's camera",
    UDModalIgnoreQRcode: "Continue with kiosk camera",
    uploadDocumentGetReadyButton: "Continue",
    uploadDocumentIamReadyButton: "I am ready",
    uploadDocumentCancelButton: "Cancel",
    uploadDocumentPreview: "Please select the highest quality image",
    uploadDocumentPreviewTakeAgain: "Retake",
    uploadDocumentPreviewValidate: "Validate photo",
    uploadDocumentAddAnotherPage: "Would you like to add another page?",
    uploadDocumentChooseType: "Choose the document type",
    uploadDocumentTypeCrm: "Medical report",
    uploadDocumentTypeOrdo: "Prescription",
    uploadDocumentTypeMT: "Medication / treatment",
    uploadDocumentTypeC: "Certificate",
    uploadDocumentTypeAG: "Biological analysis",
    uploadDocumentTypeCmd: "Adressing medical mail",
    uploadDocumentChooseTypeButton: "Confirm",
    uploadDocumentDocName: "Enter your document name",
    uploadDocumentloading: "Loading document...",
    uploadDocumentWait: "Please wait a moment...",
    uploadDocumentSuccess: "The document has been successfully imported to your profile!",
    uploadDocumentSuccessButton: "Continue",
    uploadDocumentSendButton: "Submit documents",
    UploadRecommendedText: "In order to renew your prescription, you should submit a photo of your document.",
    photoTransferText:`To analyze the biology results, please submit a photo of the documents.`,
    UploadPreviewDC:"Press the arrows or double-click on the front image to view it larger.",
    UDModalTransmit: "SUBMIT",
    UDModalIgnore: "IGNORE",
    Renewal: "Prescription Renewal",
    genericAPIError: "An error has occurred.",
    relativeConsent:"Adding a relative on MEDADOM requires their consent.",
    noCreditCardAdded: "You have not registered any card.",
    noTLC: "you do not have any teleconsultation.",
    yourCreditCards: "Your cards",
    subtitleCallAcceptedAndSoon: "is in consultation, you are the next patient.",
    waitingDoctor: "Please wait...",
    tagline: "Your health partner",
    relaunchReader:"Please restart the reader or skip to the next step",
    andYour: "And your",
    and: "And",
    stayLogged: "Stay logged",
    owner: "Owner",
    bornThe: "Born on ",
    OtherRelative: "Autre proche",
    chooseThisRelative: "Choose this relative",
    genericErrorPaymentCard: "An error occurred while adding your payment method.",
    setupIntentErrorStripe: "Unable to add the card.",
    genericErrorStripe: "An error occurred. Unable to initiate the request..",
    beSureToBeDisconnected: "Are you sure you want to disconnect?",
    finishAndDisconnect: "Finish and disconnect",
    myAdministratorAccount: "My administrator account",
    launchFullVideoTuto: "Lanch full video tuto",
    launchChapterVideoTuto: "Brow",
    videoManual: "Training videos",
    StartWithTheTerminal: "Use the terminal",
    WorkWithMedadom: "Working with MEDADOM",
    TerminalOperation: "Terminal operation",
    ClientParcours: " Patient path ",
    ToolsUseCase: "Use of tools",
    TheToolsUseCase: "The use of tools",
    UseCases: "Use cases",
    TheUseCases: "Use cases",
    ContraIndications: " Contra-indications ",
    TheContraIndications: " The contra-indications",
    Inscription: "Registration",
    ClientInscription: "Patient registration",
    HygieneProcedures: "Hygiene procedures",
    TheHygieneProcedure: "The hygiene procedure",
    Duration: "Duration",
    close: "Close",
    confirmTheSendingOfYourDocuments: "Do you confirm sending your documents to the pharmacist ?*",
    myStatistics: "My statistics",
    statistics: "Statistiques",
    statisticsSendWithSuccess: "statistics has been send to your mail",
    statisticsSendWithFailure: 'An error occurred try again later',
    myTutorialVideos: "Training videos",
    modal_pharmaAccount_title: "Professional space",
    companion: "CARE ASSISTANTS",
    modal_pharmaAccount_information: "If you have any questions, you can contact support: <br /> <span>From <b>Monday to Saturday </b>from <b>9 AM to 7:30 PM</b></span> <br /> <span>at <b>01 86 65 55 15</b></span> <br/><span>at <b>contact@medadom.com</b></span>",
    select_reason: "Select reason ...",
    select_birth_location: "birth location",
    anamneseReasonQuestion: "What is the reason for your teleconsultation ?",
    anamneseReasonQuestion_relative_male: "What is the reason for your relative's teleconsultation ?",
    anamneseReasonQuestion_relative_female: "What is the reason for your relative's teleconsultation ?",
    birthCity: "birth location",
    birthCountry: "birth Country",
    birthLocation: "birth location",
    createCardSuccess: "Your card has been added",
    createCardError: "Your card could not be added",
    city: "City",
    covidHTML: `Coronavirus\r\n (Covid 19)`,
    covid: "Coronavirus (Covid 19)",
    allergy: "Allergies",
    burn: "Burn",
    contraception: "Contraception",
    cystitis: "Cystitis",
    jointpain: "Jointpain (knee, wrist)",
    jointpainWithoutParenthesis: "Jointpain",
    diarrhea_or_vomiting: "Diarrhea or vomitting",
    toothpain: "Dental pain",
    back_pain: "Lower back pain",
    rash: "Rash",
    tired: "Exhaustion/anxiety/stress",
    fever: "Fever",
    sexual_infection: "Sexual infection",
    sore_throat: "Sore throat",
    headache: "Headache",
    stomach_ache: "Stomach aches",
    eye_pain: "Red eye and/or pain",
    cancel_warning: "If you cancel now, you will lose your spot in the waiting line. \r\n Do you still want to cancel ?",
    sting: "Sting/bite/wound",
    otitis: "Ears/otitis",
    prescription_refill: "Prescription renewal",
    period_pain: "Painful periods",
    lab_results: "Laboratory results",
    cold_and_flu: "Colds and flu-like illness",
    sinus: "Sinus or stuffy nose",
    cough: "Cough",
    otherReason: "Other",
    DMLA: "DMLA",
    DoYouHaveANir: "Do you have a carte vitale ?",
    IHaveAccount: "I have<b style='font-weight:normal'>account</b>",
    IHaveNoAccount: "I do not have <b style='font-weight:normal'>account</b>",
    IHaveNoEmail: "I certify that I do not have an email adress",
    IHaveNoNir: "I do not have a carte vitale",
    IHaveNir: "I have a carte vitale",
    RelativeHasNir: "The relative has a carte vitale",
    TooManyRequest: "Too many requests. Please try again later",
    WhenWasThelastConsultation:"When was your last consultation (physical) with an orthoptist/ophtalmologist ?",
    aPatientLogged: "A patient is currently logged in : ",
    ame: "Aide Médicale d'État",
    c2s: "Couverture Maladie Universelle",
    ald: "Affection Longue Durée",
    indicateYourRights: "Please indicate your French specific coverage :",
    indicateRelativeRights: "Please indicate your relative's French specific coverage :",
    rightsAttest: "I declare on my honor that I benefit from C2S (CMU or AME) or ALD. Otherwise, I may face legal action.",
    rightsAttestRelative: "I declare on my honor that my relative benefits from C2S (CMU or AME) or ALD. Otherwise, I may face legal action.",
    ameModalTitle: "Declaration on Honor",
    fraudIsPunished: "Any health insurance fraud is punished",
    ameModalAccept: "I declare on my honor its validity",
    ameModalCancel: "I do not",
    ameModalCancelRelative: "My relative does not benefit",
    estimatedTime: "Estimated time",
    yourPaymentMethod: "Your payment method",
    whoIs: "Who is ",
    for: "for ",
    you: "you",
    youWillPayOnlyAfterConsultation: "You will pay only after consultation",
    ameRelativeBenefit: "The relative benefits from CMU, AME, ALD",
    WeAutomaticallyDetectedBenefit: "The carte vitale indicates your membership in CMU, AME, ALD, 100%",
    ameIBenefit: "I benefit from French CMU, AME, ALD coverage",
    ameModalContent: `<table class="table-modal">
        <tr> 
          <td> 
            <img src="${cdn("icons/carte_vitale_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            <b>If you are eligible for C2S (CMU or AME) or ALD, you are entitled to a fee exemption.</b>
            For this, you must have an up-to-date certificate of your Universal Health Coverage, your State Medical Assistance, or your Long-Term Illness.
          </span>
            </td>
        </tr>
        <tr>
          <td> 
            <img src="${cdn("icons/pharmacien_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            This can be verified by the pharmacist.
            </span>
          <td>
        </tr>
        <tr>
          <td> 
            <img src="${cdn("icons/attestation_honneur_picto.svg")}"> 
          </td>
          <td>
            <span class="modal-content">
            <b>
            I declare on my honor that I meet the conditions stated above for a fee exemption, otherwise I may face legal action.
            </b>
            </span>
          </td>
        </tr>
        <table>`,
        cardRefused: "Card Refused",
        errorPaymentModalTitle:
          "Your payment card has been declined by our payment provider. Please ensure that :",
        errorPaymentModalChangeCard: "Change card",
        errorPaymentModalRetry: "Retry",
        errorPaymentModal_html: `
        <table class="table-modal small">
            <tr>
              <td> 
                <img src="${cdn("icons/picto_paiement.svg")}"> 
              </td>
              <td>
                <span class="modal-content">
                  You have <b>sufficient funds in your bank account.</b>
                </span>
              <td>
            </tr>
            <tr>
            <td> 
              <img src="${cdn("icons/picto_internet.svg")}"> 
            </td>
            <td>
              <span class="modal-content">
                You have <b>activated online payment</b>
              </span>
            <td>
          </tr>
          <tr>
            <td> 
              <img src="${cdn("icons/picto_3ds.svg")}"> 
            </td>
            <td>
              <span class="modal-content">
              You have <b>activated the 3DSecure</b> (two-factor authentification for online payment)
              </span>
            <td>
          </tr>
          <tr>
            <td> 
              <img src="${cdn("icons/picto_carte_valide.svg")}"> 
            </td>
            <td>
              <span class="modal-content">
              <b>Your card is valid</b> for payment
              </span>
            <td>
          </tr>
        <table>
        <div class="center" style="margin-top:20px"> 
          <b class="error">If necessary, please contact your bank for confirmation</b>
        </div>`,
    accountCreated: "Your account has been successfully created.",
    accountError: "Your account could not be created.",
    addARelative: "Add a relative",
    addCreditCard: "Add credit card",
    addPaimentMethod: "Add payment method",
    backToPaymentMethod: "Back to payment method",
    addPhoneNumber: "Add",
    addRelative: "Add relative",
    adminInformationWelcome: "L’équipe Medadom vous a transmis par mail, lors de votre inscription, votre identifiant ainsi que votre mot de passe. Merci de vous connecter ici avec ces identifiants. Contactez nous au 01 86 65 55 15 en cas de problème.",
    agreement: "Consentement",
    consentOfAssistance: "Consent of care assistant",
    agreementInvalid: "You have to accept the Terms and Conditions (CGUs).",
    alreadyAcount: "I already have a Medadom patient account.",
    anamneseAllergiesQuestionSubtitle: "(Pollen, drugs, ...)",
    anamneseAllergiesQuestion: "Do you have any allergies ?",
    anamneseAllergiesQuestion_relative_male: "Does your relative have any allergies ?",
    anamneseAllergiesQuestion_relative_female: "Does your relative have any allergies ?",
    anamneseAllergiesQuestionDetails: "Which allergy(ies) ?",
    anamneseDrugsQuestion: "Are you currenly on medication ?",
    anamneseDrugsQuestion_relative_male: "Is your relative on medication ?",
    anamneseDrugsQuestion_relative_female: "Is your relative on medication ?",
    anamneseIllnessQuestionSubtitle: "(Diabetes, hypertension, ...)",
    anamneseIllnessQuestion: "Do you have any chronic diseases?",
    anamneseIllnessQuestion_relative_male: "Has your relative ever had any chronic diseases ?",
    anamneseIllnessQuestion_relative_female: "Has your relative ever had any chronic diseases ?",
    anamneseIllnessQuestionDetails: "Which disease(s)?",
    anamneseOperationsQuestion: "Have you already been operated on ?",
    anamneseOperationsQuestion_relative_male: "Has your relative ever been operated on?",
    anamneseOperationsQuestion_relative_female: "Has your relative ever been operated on?",
    anamnesePregnantQuestion: "Are you pregnant or breast-feeding ?",
    anamnesePregnantQuestion_relative_female: "Is your relative pregnant or breast-feeding ?",
    antecedents: "Do you have a history of eye problems ?",
    apr: "April",
    askAgain: "Launch another request",
    ask_for_phone_re_enter:"Please enter the patient's phone number again.",
    ask_for_voiceCall: "Receive the code by voice call",
    aug: "August",
    averageConsultationTime: "Average consultation time",
    backToHome: "Back to home",
    birthdate: "Birthdate",
    birthdatePlaceholder: "DD / MM / YYYY",
    byPassEmail: "I do not have an email",
    byPassNir: "I do not have a Carte Vitale ",
    enterRelativeNir: "Enter the Carte Vitale number of the relative.",
    byPassNir_relative: "The relative doesn't have a Carte Vitale",
    noBill: "You will not be billed.",
    doctorCancelCall:"We are sorry, the doctor has canceled the teleconsultation.",
    callCancelled: "Cancelled",
    callCannotBeCancelled: "We were unable to cancel the consultation",
    callExpired: "Expired",
    callHasBeenCancelled: "The consultation has been cancelled",
    callMeVerify: "Receive a call for the code",
    call_ophtalmologist: "Teleconsultation with the ophthalmologist",
    call_ortho: "Teleconsultation with the orthoptist",
    call_page: "teleconsultation",
    cancel: "Cancel",
    cancelAndDisconnect: "Cancel and disconnect",
    cancelCall: "Cancel consultation",
    cancelConsultation: "CANCEL CONSULTATION",
    continueConsultation: "CONTINUE MY TELECONSULTATION",
    cancelTLC: "You are about to cancel the teleconsultation request",
    cancer: "Cancer",
    cannotLaunchTlc: "Unable to launch the consultation",
    cataract: "Cataract",
    cguAgreement: "I have read and accept <b>the terms and conditions</b>, as well as the processing of my personal health data for using the service",
    cguAndPrivacyTitle: "Terms and Personal Data",
    change_phone: "Change phone number",
    childLessThanSix: "children under 6 years old",
    choseYourCountry: "Please indicate the country",
    civility: "Civility",
    clickMeToVanish: "click me to vanish !",
    comeBackToLogin: "Come back to login",
    comeBackToSurvey: "Come back to survey",
    confirm: "Confirm",
    medicalcenter: "Medical center",
    chooseYourNationality: "Choose your nationality",
    confirmHangupCall: "Are you sure you want to hang up the teleconsultation ?",
    confirmCancelCall: "Are you sure you want to cancel the request ?",
    connectedAs: "Connected as ",
    consultationScheduledAt: "has confirmed the teleconsultation for this slot. There may be a delay, thank you for your patience.",
    contact_secretary: "Please contact the secretary \r\n to create your account",
    continue: "Continue",
    IUnderstandAndContinue: "I understand and continue",
    createCall: "Start the consultation",
    creating_call: "Request in progress",
    credit: "© 2021 MEDADOM All rights reserved",
    creditCard: "credit card",
    waintingForCardInsertion: "Waiting for card insertion",
    dateInvalid: "The date of birth is invalid",
    dec: "December",
    defaultErrorForm: "An error has occurred in the form.",
    defaultReplyWhichWho: "One of our doctors is available",
    deleteCard: "Delete Card",
    demo: "The demonstration of the machines",
    demo_page: "demonstration-outils",
    diabetes: "Diabetes",
    digestiveDisease: "Digestive disease",
    doYouHaveAnAccount: "Do you have a Medadom account ?",
    anamneseChoiceOfDoctor: "What would you like to do?",
    anamneseConsultNow: "Consult <b>now</b>",
    consultNow: "See a doctor now",
    now: "now",
    availability: "Availability",
    thisDoctorIsNotAvailable_html: "This doctor is unavailable, but a doctor from <b>CDS Mediksanté (Paris)</b> can <b>assist you immediately</b>",
    IConsultThisDoctor: "I want to consult with this doctor.",
    ifDoctorDoNotExist: "If the doctor you are looking for is not yet consulting on MEDADOM, you will be able to consult with another available doctor.",
    requestDoctorPlaceholder: "Enter the name of the doctor",
    anamneseChooseADoctor: "I have an appointment with a <b>specific doctor</b>",
    chooseADoctor: "I have an appointment with a specific doctor",
    anamneseChooseADoctor_relative_female: "My relative has an appointment with a <b>specific doctor</b>",
    anamneseChooseADoctor_relative_male: "My relative has an appointment with a specific doctor</b>",
    anamneseDoctorAvailableQuestion: "What is your situation?",
    nirIsExpiredTitle: "Your Carte Vitale is not up to date!",
    nirIsExpiredContent: `<span> This may affect the reimbursement of your teleconsultation,</span> <br /><b> update it with your pharmacist </b>`,
    anamneseDoctorAvailableQuestion_relative_male: "What is your relative's situation?",
    anamneseDoctorAvailableQuestion_relative_female: "What is your relative's situation?",
    doNotHaveDoctor: "<b>I do not have</b> a treating general practitioner",
    doctorNotAvailable: "My treating general practitioner <b>is not available</b>",
    doctorNotAvailable_relative_female: "My relative's treating general practitioner <b>is unavailable</b>",
    doctorNotAvailable_relative_male: "My relative's treating general practitioner <b>is unavailable</b>",
    doctorNotInList: "My doctor isn't on the list",
    doctorAwaiting: "Your doctor is ready ! Your consultation will begin.",
    waitInFrontOfTheScreen: "Please wait in front of the screen...",
    documentAvailableInfo: "Find your medical documents in your Medadom space",
    inTab: ", in the tab ",
    deviceAreON: "The otoscope (A) and/or dermatoscope (D) are on.",
    documents: "Documents",
    drugAllergy: "Drug allergy",
    foodAllergy: "Food allergy",
    penicillinAllergy:"Penicilline allergy",
    email: "email",
    notSuitableTLC: "Teleconsultation not suitable",
    IHaveRelativeConsent: "I have their consent",
    enterRelativeInformations: "Add a relative",
    enter_lastname: "Please enter your last name",
    InputYourOldPassword: "Please enter your old password",
    InputYourPassword: "Confirm your password",
    enter_password: "Set a password, \n\r for creating your Medadom account",
    enter_phone: "Enter your phone number",
    notSuitableTLCContent: "Children under <b>3 years old</b> require a clinical examination.<br/>Teleconsultation is not suitable for this and is therefore <b>not possible</b> in this case.",
    IGotIt: "I understand",
    errorCallState: "An error occurred during the call, \n\r Please contact our support",
    errorLogin: "There was an error in the form",
    errorOccuredWhenSendingCode: "An error occurred while sending the code",
    existingOrderError: "The teleconsultation already exists",
    expiredSession: "The session has expired. We will log you out",
    feb: "February",
    female: "Female",
    finalePageTitle: "Thank you for your visit!",
    firstConsultation: "First glasses consultation",
    firstname: "First name",
    first_birth_firstname: "First name",
    first_firstname: "First name",
    // Do not these two keys even though they contains empty string
    first: "",
    of_birth: "",
    // ----------------------------------
    forMe: "For me",
    forRelative: "For a relative",
    forgottenPassword: "I forgot my password",
    gender: "Gender",
    glassesRenewal: "Glasses renewal",
    glaucoma: "Glaucoma",
    errorDate_shouldBeOlderThan: (minAge?: number) => `You must be at least ${minAge || 3} years old.`,
    errorDate_shouldBeYoungerThan: (maxAge?: number) => `Teleconsultation is not possible after ${maxAge || 110}`,
    invalideDate: "Incomplete or incorrect date of birth",
    DD: "DD",
    MM: "MM",
    YYYY: "YYYY",
    goBackToProfile: "Back to profile",
    helper: "MEDADOM team informs you :",
    doctorIsWritingYourDocs: "The doctor is currently drafting your medical documents....",
    stepCanTake: "This step usually takes",
    timeWaitingHungup: "5 to 10 minutes.",
    findDocumentsInYourProfile:
      "Find your medical documents anytime from your MEDADOM space.",
    followingInstructions_html: (deviceName?: string) =>
      `Place<br/><b>${deviceName ?? "the object"
      }</b><br/>according to the doctor's instructions`,
    putDown: (deviceName?: string) =>
      `Reposer <br/> <b>${deviceName ?? "l'objet"}</b>`,
		helperRegardingGenderRelative: "Double-check the gender of the person you are adding!",
	  hangup: "Hang up",
    hangupTitleWarning: "Do you want to hang up?",
	  hangup_subtitle: "Please wait a moment...",
	  hangup_title: "Doctor is preparing documents",
	  heartDisease: "Heart disease",
	  hypertension: "Hypertension",
	  IRefuseConsent: "Cancel",
	  IAcceptConsent: "I accept",
	  consentTitleModal: "Terms of Service",
	  consentContentModal: "I have read and accept <a>the terms of service</a>, as well as the processing of my personal health data for using the service.",
	  inProgress: "In progress...",
	  inactivity: "You will be disconnected due to inactivity",
	  incorrectInput: "Please enter a valid input for",
	  infectiousDisease: "Infectious disease",
	  inform_anamnesis: "The doctor needs some medical information about you",
	  initial_step: "Your teleconsultation journey",
	  inform_anamnesis_relative_male: "The doctor needs some medical information about the relative",
    inform_anamnesis_relative_female: "The doctor needs some medical information about the relative",
	  inputYour: "Enter your ",
	  enterThe: "Enter the ",
    cannotStartTLC: "Unable to start TLC",
    IhaveReadAndAccept: "I have read and accept ",
    theCGU: "the general terms and conditions of use ",
    andThe: "and the ",
    privacyPolicies: "privacy policy ",
    ofMedadom: "of Medadom",
    cannotLoadTLC: " Unable to load tlc ",
    weHaveSendAnSms: "We've sent you an SMS!",
    cannotSendTheSms: "Impossible to resend the SMS",
    cannotStartReader: " cannot start the reader",
    cannotRetrievedProfessionalIdentity: " Unable to retrieve the identity of the healthcare professional",
    cannotRetrieveCompanions: " Unable to retrieve companions ",
    cannotCreateCompanion: " Unable to create companion: ",
    cannotDeleteCompanion: "Unable to delete companion",
    cannotGetCompanionsRelationship: "Impossible to retrieve companion relationships",
    cannotCancel: "Impossible to cancel",
	  inputYourBirthCountry: "Enter your country of birth",
	  inputYourBirthCity: "Enter your place of birth",
	  inputYourRelativeBirthCity: "Enter the relative's place of birth",
	  inputYourRelativeBirthCountry: "Enter the relative's country of birth",
	  unknownBirthPlace: "Move to the next step",
	  insertYourNir: "Insert your carte vitale",
	  createPassword: "Please create a password",
    containsAtLeast: "Contains at least:",
    tenCharacters: "10 characters",
    oneLowerCaseLetter: "A lowercase letter",
    oneUpperCaseLetter: "An uppercase letter",
    oneNumber: "One digit",
		ineligibleReasonInformation: "For these reasons, you must visit your treating physician or consult a doctor in person.",
	  invalidValidationCode: "An error occurred while validating the code.",
	  invalid_password: "Invalid password",
	  jan: "January",
	  joinCallIn: "Join the call in",
	  joinTheConsultation: "Join the consultation",
	  jul: "July",
	  jun: "June",
    keratoconus: "Keratoconus",
	  kidneyDisease: "Kidney disease",
	  lastname: "Preferred last name",
	  birthLastname: "Last name",
	  launch_tlc: "Start a teleconsultation",
	  legalMentionArticleSix: "In accordance with Article 6 of the Public Health Code, you are free to choose your optician.",
	  lensesRenewal: "Lens renewal",
	  lessThan: "Less than",
	  lessThanAYear: "Less than 1 year",
	  locationError: "Please enter a valid location",
	  locationInvalid: "Please select a valid place of birth",
	  locationAddress: "Place of birth",
	  locationAddressError: "Invalid place of birth",
	  login: "Log in",
	  loginNir: "Validate",
	  loginTitle: "I already have a patient account",
	  logoutTitle: "Do you want to log out?",
	  logout: "Log out",
	  logoutAdmin: "Pharmacy logout",
    male: "Male",
    mar: "March",
    may: "May",
    me: "me",
    medikSante: "MedikSanté",
	  mismatchingErrorForm: "Credentials do not match",
	  mustContainLessThanHundredCharacters: "The field must contain less than 100 characters",
	  cantContainSpaceAsFirstCharacters: "The first character cannot be a space or hyphen",
	  cantContainTwoConsecutiveSpace: "The field cannot contain two consecutive spaces",
	  cantContainTwoConsecutiveApostrophe: "The field cannot contain two consecutive apostrophes",
	  cantContainTwoConsecutiveHyphen: "The field cannot contain two consecutive hyphens",
	  cantContainHyphenFollowedByApostrophe: "The field cannot contain a hyphen followed by an apostrophe",
	  cantContainHyphenFollowedBySpace: "The field cannot contain a hyphen followed by a space",
	  cantFinishWithHyphenOrApostrophe: "The field cannot end with a hyphen or apostrophe",
	  shouldStartWithAlphaOrApostrophe: "The field must start with a character or apostrophe",
	  shouldContainAtLeastOneAlphaCharacter: "The field must contain at least one alphanumeric character",
	  shouldNotContainSlash: "The field cannot contain a slash",
    modify: "Modify",
    ofTheRelative: "of the relative",
    modifyRelative: "Modify my relative",
    of: "of",
    label_input_the_male: "Enter the ",
    enter1erFirstname: "Enter your first name",
    call:"Call",
    Email: "Email",
    inputThe: (name: string) => {
      if (["birthdate"].includes(name)) return "Enter the " + en[name]
      else return "Enter the " + en[name]
    },
    modifyThe: (name: string) => {
      return "Modify the " + en[name]
    },
    modifyYour: (name: string) => {
      return "Modify your " + en[name]
    },

    addA: (name: string) => {
      return "Add a " + en[name]
    },
    modifyYourPassword: "Modify your password",
    addAFirstname: "Add a firstname",
    addALastname: "Add a lastname",
    nirWellKnown: "The carte vitale number is well registered",
    exemptionEnded: "End of the government Covid-19 measure on full third-party payment. As of October 1, 2022, the patient will again have to pay the complementary part (reimbursed by the mutual insurance)",
    tlcExemptedDate: "As of October 1, 2022",
    tlcExemptedContent: "The regulatory coverage by Social Security will return to 70%, with a patient copayment of 30% (reimbursable by your mutual insurance). The current health context exempts advance payment for teleconsultation until 30/09/2022.",
    nextMeansYouAcceptCGU: "By continuing with the request, I confirm that I have read the Terms of Use",
    putHeadPhones: "If the terminal is equipped, put on the headphones",
    moreThanAYear: "More than 1 year",
    needHelp: "Need help?",
    next: "Next",
    conflictError: "Already existing",
    nexmoError: "This phone number cannot be validated. Please try with another number",
    nir: "Carte vitale number",
    nirInvalid: "The carte vitale number is not valid",
    nirLengthError: "The carte vitale number must contain 15 digits",
    rppsLengthError: "The RPPS/Adeli must contain a maximum of 11 digits",
    nirManualInput: "Skip this step",
    nirReaderError: "We could not retrieve the information",
    codeSpacer: " ",
    codeAllowedChar: "9",
    nirMaskChar: "_",
    nirAllowedChar: "*",
    no: "NO",
	  doctorIsPrescribing: "Documents being written by the doctor",
	  youWillBeInformedBySMS: "In case of advance, you will be notified by SMS",
	  youWillBeInformedBySMSFifo: "You will be notified by SMS before your teleconsultation starts",
	  noAppointment: "I do not have an appointment",
	  noAppointment_relative_male: "My relative does not have an appointment",
	  noAppointment_relative_female: "My relative does not have an appointment",
	  noDocument: "The doctor has not issued any prescription",
    noEmailContentModal: `<div>
      <div class="center" style="margin-bottom:80px">
        <img  src="${cdn("icons/mail_picto.svg")}" alt="">
      </div>
      <table class="table-modal">
        <tr>
          <td></td>
          <td><b>By leaving your email address blank:</b></td></tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/supprimer.svg"
    )}" alt=""></td>
          <td>You will not receive information on medical documents related to your consultation.</td>
        </tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/supprimer.svg"
    )}" alt=""></td>
          <td>Our medical and administrative teams will not be able to contact you in case of need.</td>
        </tr>
      </table>
    </div>`,
    noEmailTitleModal: "Email address required",
	  noRelativesYet: "There are currently no relatives",
	  noNirTitleModal: "Warning",
	  noNirContentModal: "You will have to pay the full amount for the medical teleconsultation.",
	  noHistory: "No history",
	  notAdapted: "Teleconsultation is not suitable for:",
	  notBornInFrance: "I was not born in France?",
	  bornInFrance: "I was born in France?",
	  relativeBornInFrance: "The relative was born in France?",
	  relativeNotBornInFrance: "The relative was not born in France?",
	  french: "France",
	  foreign: "Foreign",
	  unknown: "Unknown",
	  nov: "November",
	  office: "Office",
	  oct: "October",
    olderThanFifty: "Are you over 50 years old?",
	  optional: "(optional)",
	  orderForWhichDoctor: "Which doctor is your appointment with?",
	  orderForWhichDoctor_relative_male: "Which doctor is your relative's appointment with?",
	  orderForWhichDoctor_relative_female: "Which doctor is your relative's appointment with?",
	  orderForWhichHour: "At what time is your appointment?",
	  orderForWhichHour_relative_male: "At what time is your relative's appointment?",
	  orderForWhichHour_relative_female: "At what time is your relative's appointment?",
	  orderForWho: "Who is the consultation for?",
	  otherAllergy: "Other",
	  otherDisease: "Other",
	  otherOphtalmologicDisease: "Other",
    page_admin: "admin",
	  page_check_lastname: "validate-last-name",
	  page_check_password: "validate-password",
	  page_check_phone: "validate-phone",
	  page_home: "home",
	  page_insert_relative: "add-relative",
	  page_lobby: "choose-connection",
	  page_login: "login",
    page_nir: "carte-vitale",
		page_not_authorized: "401",
	  page_not_found: "404",
	  page_order: "questions",
	  page_register: "register",
	  page_register_personal_data: "enter-personal-data",
	  page_reset_password: "reset-password",
	  page_verify: "verify",
	  page_verify_phone: "verify-mobile",
	  page_welcome: "welcome",
    panel_for_secretary: "If you are the secretary, please enter the admin password",
	  password: "Password",
	  passwordMustBeEightLength: "The password must be at least 8 characters long",
	  password_validation_char: "8 characters",
	  password_validation_lower: "one lowercase letter",
	  password_validation_number: "one number",
	  password_validation_title: "Your password must contain at least:",
	  password_validation_upper: "one uppercase letter",
	  patientPutError: "We could not update your profile",
	  patientPutNirError: "Your social security number is not valid",
	  patientPutNirSuccess: "Your social security number is valid",
	  payloadErrorForm: "There was an error in the request",
	  peoplesOlderThanSixtyFive: "people over 65",
	  phone: "Phone Number",
	  phoneExistingErrorForm: "The phone number is already used for another account",
	  phoneInvalid: "The phone is not valid",
	  phoneNotFound: "The phone number does not exist in our database",
	  playerStopped: "The reader has stopped.",
	  pollenAllergy: "Pollen allergy",
	  postCallTitle: "`Teleconsultation successfully completed`",
	  post_call_page: "transition",
    previous: "Back",
	  print: "Print",
	  rePrint: "Reprint",
	  rePrintTitle: "Reprint",
	  rePrintContent: "You have already sent a print request. Are you sure you want to reprint the document?",
	  beSureDocumentsArePrinted: "Make sure your documents have been printed or shared with the pharmacist before disconnecting",
	  printDocument: "Print documents",
	  profile_myHistory: "Documents",
	  profile_myInformations: "Information",
	  profile_myPayments: "Payment methods",
	  profile_mySettings: "Settings",
	  provideYourMail: "Please enter your email for any needed follow-up on your teleconsultation",
	  questionForWho: "Who is the consultation for?",
	  questionWhichRelative: "For which relative?",
	  questionWhichTime: "At what time is your appointment?",
	  questionWhichWho: "Which doctor is your appointment with?",
	  question_step: "pre-consultation questions",
	  reset: "Clear the field",
    re_enter_phone: "Re-enter my phone number",
	  readerInitialize: "Initialization...",
	  readerSocketGenericError: "An error occurred with the card reader,\r\n Please enter it manually",
	  readerSocketReadError: "An error occurred while reading your card",
	  readerStopped: "The reader has stopped",
    reading: "\tReading in progress...",
	  reason: "What is the reason for your visit?",
	  reconnectAfterQuestion: "You will be reconnected to your consultation after completing the pre-consultation questions",
	  redirectAfterQuestion: "You will return to the last step (ophthalmologist appointment) after completing the pre-consultation questions",
	  register: "Register",
	  registerStep_complementary: "Credentials",
	  registerStep_creation: "Account creation",
    registerStep_nir: "Carte vitale",
    registerStep_personal: "Personal information",
	  registerStep_verification: "Phone",
	  registerTitle: "I am registering",
	  register_creation_inProgress: "Account creation in progress",
	  register_validation_inProgress: "Account validation in progress...",
	  relative: "relative",
	  relativesAddedError: "Your relatives could not be added to your account",
	  relativesAddedSuccess: "Your relatives have been successfully added",
	  relativeUseAnother: (name) => `The relative uses another ${name}`,
    relativeUseAnotherFirstname: `The relative uses another Firstname`,
    relativeUseAnotherLastname: `The relative uses another Lastname`,
	  relaunchPlayer: "Restart the reader",
	  removeYourNir: "Please remove your card",
	  resendCodeInfo: "By clicking the button, a new SMS will be sent to you",
	  resend_code: "Resend the code via SMS",
		resetPasswordRequestIsMadeTitle: (transport: 'sms' | 'email') => `If an account is associated with this phone number, an ${transport} has been sent to you`,
    ifMailIsMissing: "if there is no mail linked to this account, the reinit will be done by SMS",
	  resetPasswordTitle: "Enter your phone number to reset your password",
	  respiratoryDisease: "Respiratory disease",
	  referrerIsAHealthProfessional: "If you are not at a pharmacy, the documents will be sent to the system's referrer",
    scheduledConsultation: "Scheduled teleconsultation",
	  subscribePlaceholder: "Allow MEDADOM to send you communications via email",
	  doctorBusy: "All our doctors are busy. We are sorry.",
	  selectDayBirthdate: "Day",
	  selectMonthBirthdate: "Month",
	  selectYearBirthdate: "Year",
	  sendCode: "Send code",
	  sendDocumentsToTheReferrer: "Send to the pharmacist",
	  sent: "Sent!",
	  sep: "September",
	  smsNotReceived: "I did not receive the SMS",
	  step_page: "step",
	  strabismus: "Strabismus",
	  supportNumber: "support 01 86 65 55 15",
	  teleconsultationTraining: "Teleconsultation training",
	  tlcMade: "Teleconsultations conducted",
	  thanks: "Thank you",
	  turnThemOff: "Please turn them off before continuing.",
	  tlcOf: "Teleconsultation on",
	  thanksForYourAnswers: "Thank you for answering the questions. The provided data remains confidential.",
    thirtyMinutes: "30 min",
    tooltipResetPassword: "An SMS will be sent to you with the login information",
	  tooManyCallsForACustomer: "You have already had a teleconsultation today",
	  touch_screen: "Touch the screen to start",
	  tryAgain: "Restart the reader",
	  useCard: "Start the teleconsultation",
    useAnother: (name) => `I use another ${name} daily`,
    IuseAnotherFirstname: "I use another Firstname daily",
    preferedLastname: "preferred last name",
    preferedFirstname: "preferred first name",
    IuseAnotherLastname: "I use another Lastname daily",
    male1stBirthFirstnamePlaceholder: "Jean...",
    male1stFirstnamePlaceholder: "Pierre...",
    BirthLastnamePlaceholder: "Dupont...",
    LastnamePlaceholder: "Durant...",
    female1stBirthFirstnamePlaceholder: "Marie...",
    female1stFirstnamePlaceholder: "Nathalie...",
    unknownBirthLocation: "Unknown place of birth",
    validate: "Validate",
    HealthProfessional: "Health Care Professional",
    Referer: "Referrer",
    verifiedPhoneError: "The verification code is incorrect",
    companionIdentification: "Assistant identification",
    verifiedPhoneSuccess: "Your phone has been successfully verified",
    verifyCallSuccess: "An SMS has been resent to you.",
    verifyCallSendSuccess: "You will receive a call in a few seconds",
    verifyNir: "Verify the numbers before proceeding to the next step",
    waitingForNirReader: "Searching for the Carte Vitale reader...",
    answerIn: "Searching for availability may take up to ...",
    tlcExpectedIn: "Teleconsultation expected in: ",
    weAreLookingForDoctors: "We are currently looking for an available doctor, please wait.",
    weekly: "This week",
    monthly: "This month",
    yearly: "This year",
    total: "Total",
    daily: "Today",
    in: "In",
    welcome: "Welcome",
    wichOnes: "Which ones (multiple selection possible)?",
    writeNir: "Enter your numbers",
    writeVerifyCode: "Enter the code you received at ",
    sendItAgain: 'Resend code',
    SignupSuccess: "You have successfully registered!",
    RefraichToUpdateInfo: "Please refresh to update the information",
    WrongCode: "The code seems to be wrong",
    wrongNirLength: "Please enter 15 digits",
    wrongWay: "It seems you did not arrive here by following the correct path",
    yes: "YES",
    youHaveCancelledCall: "You have cancelled the teleconsultation",
    youNeedToRemoveLensesBeforeConsultation: "The medical examination requires removing your contact lenses the day before the teleconsultation.",
    youWillLaunchATLC: "You are about to launch a teleconsultation request",
    yourDocuments: "Your Documents",
    yourPharmaAccount: "Your pharmacy account",
    yourWaitingTime: "Your estimated waiting time:",
// tutorial
    take: "Take",
    itIsDone: "It's done!",
    finish: "Finish",
    iTurnOff: "I have turned off",
    turnOffDevice_html: (
      deviceName: string
    ) => `<b>Slide the cursor to turn off ${deviceName}</b>
    <br />
    (OFF position)`,
    turnOnDevice_dermatoscope_html: `Slide <b>the cursor all the way</b> to turn on<br />
    (LED position)`,
    turnOnDevice_otoscope_html: `Slide <b>the cursor all the way</b> to turn on<br />
    (LED2 position)`,
    seemsDeviceNotOn: (
      deviceName: string
    ) => `It seems that ${deviceName} is not working or is not plugged in`,
    lightDevice_html: `Slide <b>the brightness knob to the maximum</b>`,
	  focusDevice_html: `<b>Adjust the image sharpness</b> if needed <b>by moving the grey knob</b>`,
	  isNotTurnOn: "is not plugged in",
	  inUsing: "In use",
    useVideoDevice_html: (deviceNaming: string) => {
    if (deviceNaming === "otoscope")
      return `Place <b>your thumb on the grey knob</b> then <b>insert the tip</b> into the ear`
    if (deviceNaming === "dermatoscope")
      return "<b>Place the tip on the area to be shown</b>"
  },
  updateVerifyMessage: (type:"email"|"sms") => {
    if(type==="email"){
      return "An email has been sent!"
    }
    if(type==="sms"){
      return "A sms has been sent!"
    }
  },
  price: "Tarif",
  MoreDetails: " Read more...",
  CouldntSendTheCode: "The code could not be resent.",
  profilUpdated: "We have successfully modified your profile",
  passwordIdentical: "The two passwords are identical",
  impossibleToModify: "Impossible to modify your",
  RelativeHasBeenUpdated: "We have successfully modified your relative's information",
  RelativeModificationImpossible: "Impossible to modify the relative",
  SomethingWentWrong: "An error has occurred",
  WrongVerificationCode: "The verification code is incorrect",
  warning: "Warning",
  modalContent_changePatientInformation_html: "If you wish to teleconsult for <b>another person</b>, you<br/> must <b>add a relative</b> during your next teleconsultation process<br/> <b>and not modify the data</b>",
  modifyDatas: "Modify data",
  datas_and_consent_title: "Data and Consent",
  datas_and_consent_point_1: "I confirm my free decision for a teleconsultation",
  datas_and_consent_point_2: "I accept the collection of my necessary personal data:",
  datas_and_consent_point_2_checkbox_1: "for my care pathway (administrative, medical record management, billing, SMS)",
  datas_and_consent_point_2_checkbox_2: "for the establishment of anonymous statistics and product improvement in compliance with applicable regulations",
  datas_and_consent_point_3: "Medadom and its subcontractors protect your health data. For more information and potential conflicts of interest, see",
  datas_and_content_point_3_ref: "the privacy policy",
  IConfirmAndContinue: "I confirm and continue",
  verifyEmail: "To confirm the change, enter the code received by email at the following address",
  add: "Add",
  IConfirmmyConsentDataChoice: "I confirm my free decision for a teleconsultation and accept my personal data collection in this context.",
  IConfirmMyConsentDataChoiceExempte: "I confirm that I have read the T&Cs, my free decision for a teleconsultation, and accept my personal data collection.",
  verifyPhone: "To confirm the change, enter the code received at the following number",
  addCarer: "Add a carer",
  addingCarer: "Adding a carer",
  addProfessional: "Add a professional",
  proOrReferent: "A healthcare professional or referent",
  aRelative: "A relative",
  asCarer: "As s Carer",
  iConsentTo: 'I consent to',
  keepInformationConfidential: 'keep the information exchanged confidential',
  duringTLC: 'during the teleconsultation',
  iCommit: 'I commit',
  validateAndContinue: "I validate and continue",
  insertAdminEmail: "For security reasons, please ask the teleconsultation system administrator to provide his email",
  IsaPro: "Is it a healthcare professional?",
  isCarerInTheList: "Is the carer in the list?",
  chooseALanguage: "Choose a Language",
  exemplePro: "(pharmacist, nurse, ...)",
  whichRelativeIsCaringYou: "Which relative is assisting you?",
  whichProfessionalIsCaringYou: "Which professional is assisting you?",
  whichProfessionalIsCaringYou_relative: "Which professional is assisting your relative ?",
  whatIsRelationship: "What is the relationship of the carer ?",
  confirmRemoveCarer: "You are about to remove the following carer :",
  incorrectFieldAdminEmail: "The field is incorrect",
  forMoreSecurity: "For more security",
  insertFirstnameCarer: "Enter the carer's first name",
  insertLastnameCarer: "Enter the carer's last name",
  insertPhoneCarer: "Enter the carer's phone number",
  insertRpps: "Enter the RPPS / Adeli of the assisting healthcare professional",
  addPro: "Would you like to add this professional ?",
  healthPro: "Healthcare professional",
  delete: "Delete",
  otherCarer: "Other carer",
  noCarer: "No carer",
  ImCaredForThisTlc_relative: "My relative is not autonomous and is assisted for this consultation",
  ImCaredForThisTlc: "I am not autonomous and I am assisted for this consultation",
  whoIsCaringYouForTheTLC: "Who is assisting you for this teleconsultation?",
  whoIsCaringYouForTheTLC_relative: "Who is assisting your relative for this teleconsultation?",
  whoIsCaringYou: "Who will be assisting you?",
  whoIsCaringYou_relative: "Who is assisting your relative ?",
  required: "Wrong value",
  passwordHasNotBeenChangedSince_html: (days: number) => {
    if (days <= 0)
      return `<div>Your password has <b>expired</b>, it's been more than ${Math.abs(days)} ${Math.abs(days) <= 1 ? "day" : "days"} since you last changed it. For greater <b>security</b>, please change it <b>now</b>.</div>`
    if (days === 1)
      return `<div> Your password <b>expires tomorrow.</b> For <b>security</b> reasons, we encourage you to renew it now.</div>`
    return `<div> Your password <b>expires in ${days} days.</b> For <b>security</b> reasons, we encourage you to renew it now.</div>`
  },
  later: "Later",
  modifyPassword: "Change my password",
  uneligibleTitle: "Please note that teleconsultation is not suitable for the following reasons:",
  lessthan3yearOld: "Children under 3",
  unaccompaniedMinors: "Unaccompanied underage",
  ExtendedSickLeave: "Extension of previous workleave",
  fitnessCertificate: "Certificate of suitability (sport, nursery)",
  PrescriptionOfPsychoactive: "Prescription of psychoactive dependence treatments",
  IhaveUnderstood: "Continue Request",
  photoOfBurn: "(photos of the burn, ...)",
  photoOfJoins: "(photos of the joints, ...)",
  photoOfLesion: "(photos of lesion, ...)",
  photoOfThroat: "(photos of the throat, ...)",
  photoOfTheEye: "(photos of the eye, ...)",
  photoOfSting: "(photos of the sting, ...)",
  PrescriptionToBeRenewed: "(prescription to be renewed, treatment, ...)",
  analysisResults: "(analysis results, ...)",
  mister: "M.",
  miss: "Ms",
  sendBackTheCode: "REQUEST A NEW CODE IN",
  scanTogetSupport: "To access the MEDADOM Help Center, please scan this QR Code:",
  youCanContactSupport: "You can also contact support:",
  mondayToSaturday: "from Monday to Saturday, 9 a.m. to 7:30 p.m.",
  atSupportNumber: "on 01 86 65 55 15",
  skipStep: "skip step",
  addYourWeight: "add your weight",
  addYourTemperature: "add your temperature",
  kilogram: 'kg',
  celsius: '° C',
  iTookMyTemperature: "I took my temperature",
  unableToProcessVideo: "Sorry we are not able to process the video",
  choosePasswordRenewMethod: "Choose a method to receive the link to reset your password",
  estimatedWaitingTime: "estimated waiting time : ",
  takeYourTemperature: "Take your temperature",
};

export default en;